<template>
  <page-layout class="fs-page">
    <div slot="headerContent">
      <h1 class="title">登记</h1>
    </div>
    <div slot="extra">
      <a-button @click="handleSpotAdd" icon="up-square" type="primary" style="margin-left: 10px">登记集污点</a-button>
      <a-button @click="handleDamageReportAdd" icon="plus-circle" type="primary" style="margin-left: 10px">上报登记
      </a-button>
      <a-button @click="exportWord()" icon="export" type="default" style="margin-left: 10px">导出记录</a-button>
    </div>
    <a-card :bordered="false">
      <div class="fs-search-box">
        <div class="table-page-search-wrapper">
          <a-form layout="inline">
            <a-form-item label="任务状态">
              <a-radio-group
                v-model="searchParams.bindStatus" @change="search">
                <a-radio-button value="">
                  全部
                </a-radio-button>
                <a-radio-button value="NOTOPEN">
                  未审核
                </a-radio-button>
                <a-radio-button value="OPTIONAL">
                  待接单
                </a-radio-button>
                <a-radio-button value="CHECKED">
                  已接单
                </a-radio-button>
                <a-radio-button value="DOING">
                  清运中
                </a-radio-button>
                <a-radio-button value="COMPLETE">
                  已清运
                </a-radio-button>
              </a-radio-group>
            </a-form-item>
            <a-form-item label="是否紧急">
              <a-radio-group v-model="searchParams.ifUrgent" @change="search">
                <a-radio-button value="">全部</a-radio-button>
                <a-radio-button value="true">是</a-radio-button>
                <a-radio-button value="false">否</a-radio-button>
              </a-radio-group>
            </a-form-item>
            <a-form-item label="集污点类型">
              <a-select default-value="ALL"
                        style="width: 130px"
                        @change="selectSpotType"
                        v-model="searchParams.spotType">
                <a-select-option value="ALL">全部</a-select-option>
                <a-select-option
                  v-for="item in spotTypeList"
                  :key="item.value"
                  :value="item.value">{{ item.label }}
                </a-select-option>
              </a-select>
            </a-form-item>
            <a-form-item label="处理状态">
              <a-select allowClear showSearch
                        v-model="searchParams.handleStatus"
                        placeholder="请选择处理状态"
                        @change="search" style="width: 150px">
                <a-select-option
                  v-for="(item, index) in processStatusList"
                  :key="index"
                  :value="item.value"
                  :label="item.label">
                  {{ item.label }}
                </a-select-option>
              </a-select>
            </a-form-item>
            <a-form-item label="">
              <area-selection @onclickArea="onclickArea" @search="search"></area-selection>
            </a-form-item>
            <a-form-item class="f-r m-r-none">
              <a-button type="primary" @click="search" icon="search">查询</a-button>
              <a-button class="m-l-6" @click="resetForm" icon="reload">重置</a-button>
              <a :style="{ marginLeft: '5px', fontSize: '12px' }" @click="toggle">
                {{ expand ? '收起' : '展开' }}
                <a-icon :type="expand ? 'up' : 'down'"/>
              </a>
            </a-form-item>
            <a-row v-if="expand" style="margin-top: 10px;margin-bottom: 10px;">
              <a-form-item label="人员名称">
                <a-input v-model="searchParams.fromName" @change="search" placeholder="人员名称" style="width: 120px"/>
              </a-form-item>
              <a-form-item label="来电电话">
                <a-input v-model="searchParams.fromPhone" @change="search" placeholder="来电电话" style="width: 120px"/>
              </a-form-item>
              <a-form-item label="清运司机">
                <a-tree-select
                  allowClear
                  :treeData="userList"
                  v-model="executeUserStr"
                  @change="driverChange"
                  placeholder="请选择执行司机">
                </a-tree-select>
              </a-form-item>
              <!--   <a-form-item label="清运区域">
                   <a-cascader :options="areaOptions"
                               :defaultValue="areaDefaultValue"
                               v-model="areaDefaultValue"
                               placeholder="选择清运区域" @change="areaOnChange">
                   </a-cascader>
                 </a-form-item>-->
              <a-form-item label="集污点编号">
                <a-input v-model="searchParams.no" @change="search" placeholder="集污点编号"/>
              </a-form-item>
              <a-form-item label="上报原因">
                <a-input v-model="searchParams.reason" @change="search" placeholder="上报原因"/>
              </a-form-item>
              <a-form-item label="来电地址">
                <a-input v-model="searchParams.fromAddress" @change="search" placeholder="来电地址"/>
              </a-form-item>
              <a-form-item label="值班人员">
                <a-input v-model="searchParams.officer" @change="search" placeholder="值班人员"/>
              </a-form-item>
              <a-form-item label="上报时间">
                <a-range-picker @change="dateChange" v-model="dateSearch">
                  <a-icon slot="suffixIcon" type="calendar"/>
                </a-range-picker>
              </a-form-item>
              <a-form-item label="上报类型">
                <a-radio-group
                  v-model="searchParams.type" @change="search">
                  <a-radio-button value="TEL">
                    电话
                  </a-radio-button>
                  <a-radio-button value="WECHAT">
                    微信
                  </a-radio-button>
                  <a-radio-button value="APP">
                    APP
                  </a-radio-button>
                  <a-radio-button value="OTHER">
                    其他
                  </a-radio-button>
                </a-radio-group>
              </a-form-item>
              <a-form-item label="车辆负责人">
                <a-tree-select
                  allowClear
                  :treeData="userList"
                  v-model="userStr"
                  @change="dispatcherSearch"
                  placeholder="请选择车辆负责人"
                >
                </a-tree-select>
              </a-form-item>
            </a-row>
            <a-row :gutter="[16,16]">
              <a-spin :spinning="spinning">
                <a-col :sm="24" :md="12" :lg="8" :xl="6">
                  <a-card>
                    <a-statistic
                      title="上报总数"
                      :value="pagination.total"
                      :valueStyle="valueStyle"
                      style="text-align: center;"
                    >
                    </a-statistic>
                  </a-card>
                </a-col>
                <a-col :sm="24" :md="12" :lg="8" :xl="6">
                  <a-card>
                    <a-statistic
                      title="已调度"
                      :value="damageStatistics.SCHEDULED ? damageStatistics.SCHEDULED: 0"
                      :valueStyle="valueStyle"
                      style="text-align: center;"
                    >
                    </a-statistic>
                  </a-card>
                </a-col>
                <!--<a-col :sm="24" :md="12" :lg="8" :xl="4">
                  <a-card>
                    <a-statistic
                      title="处理中"
                      :value="damageStatistics.PROCESSING"
                      :valueStyle="valueStyle"
                      style="text-align: center;"
                    >
                    </a-statistic>
                  </a-card>
                </a-col>-->
                <a-col :sm="24" :md="12" :lg="8" :xl="6">
                  <a-card>
                    <a-statistic
                      title="待回访"
                      :value="damageStatistics.PROCESSED ?damageStatistics.PROCESSED:0 "
                      :valueStyle="valueStyle"
                      style="text-align: center;"
                    >
                    </a-statistic>
                  </a-card>
                </a-col>
                <!-- <a-col :sm="24" :md="12" :lg="8" :xl="4">
                   <a-card>
                     <a-statistic
                       title="待确认"
                       :value="damageStatistics.FEEDBACK_COMPLETE"
                       :valueStyle="valueStyle"
                       style="text-align: center; color: #1890ff;"
                     >
                     </a-statistic>
                   </a-card>
                 </a-col>-->
                <a-col :sm="24" :md="12" :lg="8" :xl="6">
                  <a-card>
                    <a-statistic
                      title="已完成"
                      :value="damageStatistics.FEEDBACK_COMPLETE  ? damageStatistics.FEEDBACK_COMPLETE  : 0 + damageStatistics.CONFIRM_COMPLETE  ? damageStatistics.CONFIRM_COMPLETE : 0"
                      :valueStyle="valueStyle"
                      style="text-align: center; color: #1890ff;"
                    >
                    </a-statistic>
                  </a-card>
                </a-col>
              </a-spin>
            </a-row>
          </a-form>
        </div>
      </div>
      <a-table
        size="middle"
        :rowKey="(record) => record.id"
        @change="tableChange"
        :pagination="pagination"
        :columns="columns"
        :dataSource="tableData"
        :loading="tableLoading"
        :customRow="handleView"
        :scroll="{ x: 1000 }">
        <template slot="_index" slot-scope="text, record, index">
          {{ searchParams.number * searchParams.size + index + 1 }}
        </template>
        <template slot="name" slot-scope="text, record">
          <a-icon v-if="record.openId" style="color:#2ba245" type="wechat" theme="filled"/>&nbsp;<a-icon
          v-if="record.ifUrgent" style="color:#ff644e" type="fire" theme="filled"/>&nbsp;&nbsp;&nbsp;{{ record.spot ? record.spot.name : '' }}
        </template>
        <!--<template slot="areaName" slot-scope="text, record">
          <span v-if="record.spot  && record.spot.areaName">{{record.spot.areaName}}</span>
          <span v-else>{{record.fromAddress}}</span>
        </template>-->
        <template slot="type" slot-scope="text, record">
          <div v-if="record.type==='TEL'">
            电话
          </div>
          <div v-if="record.type==='WECHAT'">
            微信
          </div>
          <div v-if="record.type==='APP'">
            APP
          </div>
          <div v-if="record.type==='OTHER'">
            自主任务
          </div>
        </template>
        <template slot="fromAddress" slot-scope="text, record">
          <a-tooltip>
            <template slot="title">
              <div v-if="record.spot && record.spot.houseNo">
                {{ record.fromAddress + '[' + record.spot.houseNo + ']' }}
              </div>
              <div v-else> {{ record.fromAddress }}</div>
            </template>
            <div v-if="record.spot && record.spot.houseNo">{{
              record.fromAddress + '[' +
              record.spot.houseNo + ']'
              }}
            </div>
            <div v-else> {{ record.fromAddress }}</div>
          </a-tooltip>
        </template>
        <template slot="handleStatus" slot-scope="text, record">
          <a-tag v-if="record.handleStatus==='SCHEDULED'" color="#33CC33">
            已调度
          </a-tag>
          <a-tag v-if="record.handleStatus==='PROCESSING'" color="#6666CC">
            处理中
          </a-tag>
          <a-tag v-if="record.handleStatus==='PROCESSED'" color="#CC6699">
            待回访
          </a-tag>
          <!--<a-tag v-if="record.handleStatus==='FEEDBACK_COMPLETE'" color="#FF9900">
            待确认
          </a-tag>-->
          <a-tag v-if="record.handleStatus==='FEEDBACK_COMPLETE'">
            已完成
          </a-tag>
        </template>
        <template slot="bindStatus" slot-scope="text, record">
          <a-tag v-if="record.bindStatus==='NOTOPEN'" color="red">
            未审核
          </a-tag>
          <a-tag v-if="record.bindStatus==='OPTIONAL'" color="blue">
            待接单
          </a-tag>
          <a-tag v-if="record.bindStatus==='CHECKED'" color="green">
            已接单
          </a-tag>
          <a-tag v-if="record.bindStatus==='DOING'" color="pink">
            清运中
          </a-tag>
          <a-tag v-if="record.bindStatus==='COMPLETE'" color="#CC9966">
            已清运
          </a-tag>
        </template>
        <span slot="action" slot-scope="text, record">
          <template>
            <a-button
              type="primary"
              shape="circle"
              icon="carry-out"
              size="small"
              v-if="(record.bindStatus ==='OPTIONAL' || record.bindStatus ==='NOTOPEN')"
              @click.stop="dispatch(record)"
            ></a-button>
            <a-divider type="vertical"
                       v-if="((record.bindStatus ==='OPTIONAL' || record.bindStatus ==='NOTOPEN') && (record.handleStatus==='SCHEDULED' || record.handleStatus==='PROCESSING'))"/>
            <a-button
              type="default"
              shape="circle"
              icon="edit"
              size="small"
              style="color: #46c190"
              v-if="(record.handleStatus==='SCHEDULED' || record.handleStatus==='PROCESSING') && (record.bindStatus ==='OPTIONAL' || record.bindStatus ==='NOTOPEN'|| record.bindStatus ==='CHECKED')"
              @click.stop="handleEdit(record.id)"
            ></a-button>
            <a-divider type="vertical"
                       v-if="(record.handleStatus==='SCHEDULED' || record.handleStatus==='PROCESSING') && (record.bindStatus ==='OPTIONAL' || record.bindStatus ==='NOTOPEN'|| record.bindStatus ==='CHECKED')"/>
              <a-button
                :type="record.ifUrgent? 'danger' : 'default'"
                shape="circle"
                icon="fire"
                size="small"
                @click.stop="handleIfUrgent(record)"
              ></a-button>
            <a-divider type="vertical"
                       v-if="(record.handleStatus==='SCHEDULED' || record.handleStatus==='PROCESSING') && (record.bindStatus ==='OPTIONAL' || record.bindStatus ==='NOTOPEN')"/>
            <a-button
              type="default"
              @click.stop="handleDelete(record.id, '确认要删除该条上报信息吗')"
              shape="circle"
              icon="delete"
              size="small"
              style="color: #ff644e"
              v-if="(record.handleStatus==='SCHEDULED' || record.handleStatus==='PROCESSING') && (record.bindStatus ==='OPTIONAL' || record.bindStatus ==='NOTOPEN')"
            ></a-button>
          </template>
        </span>
      </a-table>
    </a-card>
    <!--添加Modal-->
    <a-modal
      width="1200px"
      title="修改上报内容"
      :visible="editModal"
      :maskClosable="false"
      @ok="handleEditOk"
      @cancel="closeEditModal">
      <register-form ref="editRef" type="edit" @editSuccess="editSuccess" @editError="editError"></register-form>
    </a-modal>
    <a-modal
      title="报抽登记"
      :visible="addModal"
      width="1200px"
      :maskClosable="false"
      centered
      @ok="handleAddOk"
      @cancel="closeAddModal">
      <register-form ref="addRef" type="add" @addSuccess="addSuccess" @addError="addError"></register-form>
    </a-modal>
    <a-modal
      width="800px"
      title="调度指派"
      :visible="dispatchModal"
      @ok="dispatchOk"
      @cancel="closeDispatchModal">
      <DispatchAssignForm ref="dispatchRef" type="dispatch" @dispatchSuccess="dispatchSuccess"
                          @dispatchError="dispatchError"></DispatchAssignForm>
    </a-modal>
    <a-drawer
      title="任务登记详情"
      placement="right"
      :closable="true"
      @close="detailClose"
      width="800px"
      :visible="detailDrawer"
      :mask="false">
      <report-detail ref="viewRef"></report-detail>
    </a-drawer>
    <a-modal :width="1000"
             title="登记集污点信息"
             v-model="addSpotModal">
      <spot-form ref="addSpotRef" type="add" @addSuccess="addSpotSuccess" @addError="addSpotError"></spot-form>
      <template slot="footer">
        <a-button @click="closeSpotAddModal">取消</a-button>
        <a-button :loading="addLoading" @click="handleSpotAddOk" type="primary" style="margin-right: 5px;">保存
        </a-button>
        <a-button :loading="addLoading" @click="handleSpotAddOkWithReport" type="primary" style="margin-right: 5px;">
          保存登记
        </a-button>
      </template>
    </a-modal>
  </page-layout>
</template>

<script>
  import SERVICE_URLS from '@/api/service.url'
  import {
    reportColumns,
    reportTypeList,
    processStatusList,
    bindStatusList,
    updateIfUrgentFormVO
  } from './common/common'
  import PageLayout from '@/components/page/PageLayout'
  import RegisterForm from './Form'
  import ReportDetail from './ReportDetail'
  import entityCRUD from '../../../common/mixins/entityCRUD'
  import moment from 'moment'
  import DispatchAssignForm from './DispatchForm'
  import SpotForm from '../../gatherspot/Form'
  import AreaSelection from '../../statistics/loophistory/AreaSelection'
  import { spotTypeEnum, spotTypeList } from '@/constant/spot/spotType'

  export default {
    name: 'ReportList',
    components: { DispatchAssignForm, RegisterForm, PageLayout, ReportDetail, SpotForm, AreaSelection },
    mixins: [entityCRUD],
    data () {
      return {
        editLoading: false,
        entityBaseUrl: SERVICE_URLS.csgz.reportRecord,
        detailDrawer: false,
        addModal: false,
        dispatchModal: false,
        addSpotModal: false,
        addSpotWithReport: false, //登记集污点完成后是否上报登记
        userStr: undefined,
        executeUserStr: undefined,
        spotTypeEnums: spotTypeEnum,
        spotTypeList: spotTypeList(),
        searchParams: {
          number: '0',
          size: '10',
          direction: '',
          order: '',
          fromName: '',
          fromPhone: '',
          fromAddress: '',
          startFromTime: '',
          endFromTime: '',
          reason: '',
          type: '',
          gatherSpotType: '',
          officer: '',
          driverId: '',
          dispatcherId: '',
          no: '',
          startDispatchTime: '',
          endDispatchTime: '',
          startHandleCompleteTime: '',
          endHandleCompleteTime: '',
          startFeedbackTime: '',
          endFeedbackTime: '',
          startConfirmTime: '',
          endConfirmTime: '',
          confirmUserId: '',
          handleStatus: undefined,
          bindStatus: '',
          areaNo: '', //区域编码
          codes: '',
          spotType: 'ALL',
          ifUrgent: ''
        },
        initColumns: reportColumns(),
        tableData: [],
        pagination: {
          current: 1,
          total: 1,
          pageSize: 10,
          showSizeChanger: true,
          showTotal: (total, range) => `第 ${range[0]}-${range[1]} 条 共${total}条记录`
        },
        expand: false,
        dateSearch: null, //日期绑定数据
        /*区域参数*/
        areaDefaultValue: null,
        areaOptions: [],
        userList: [],
        processStatusList: processStatusList(),
        bindStatusList: bindStatusList(),
        reportTypeList: reportTypeList(),
        damageStatistics: {},
        valueStyle: {
          color: '',
          textAlign: 'center'
        },
        damageReportId: '', //当前点击记录ID
        formItem: updateIfUrgentFormVO()
      }
    },
    mounted () {
      this.search()
    },
    created () {
      this.getStatisticsData()
      this.search()
      this.loadUser()
      this.getCascadeData()
    },
    computed: {
      currentUser () {
        return this.$store.getters.currentUser
      }
    },
    methods: {
      moment,
      selectStatus () {
        this.search()
      },
      getCascadeData () {
        this.$http(this, {
          url: SERVICE_URLS.csgz.areaDictionaryApi.cascade,
          noTips: true,
          success: (data) => {
            this.areaOptions = data.body
          }
        })
      },
      search () {
        this.restPage()
        this.loadTableData()
        this.getStatisticsData()
      },
      loadUser () {
        this.$http(this, {
          url: SERVICE_URLS.organization.orgUserTree,
          data: {
            authPrefix: 'org_',
            chooseType: 'RADIO'
          },
          noTips: true,
          success: (data) => {
            this.userList = data.body
          }
        })
      },
      dispatcherSearch () {
        this.searchParams.dispatcherId = this.userStr ? this.userStr.split('_')[2] : undefined
        this.search()
      },
      getStatisticsData () {
        this.spinning = true
        this.$http(this, {
          url: SERVICE_URLS.csgz.reportRecord.statistics,
          noTips: true,
          success: (data) => {
            this.damageStatistics = data.body
            this.spinning = false
          }
        })
        this.spinning = false
      },
      deleteSucceed () {
        this.getStatisticsData()
        this.search()
      },
      dateChange (date, dateString) {
        this.searchParams.startFromTime = dateString[0]
        this.searchParams.endFromTime = dateString[1]
        this.search()
      },
      /*重置*/
      resetForm () {
        this.areaDefaultValue = '' //区域
        this.userStr = undefined //车辆负责人
        this.executeUserStr = undefined //清运司机
        this.dateSearch = null  //上报时间
        this.searchParams.startFromTime = ''
        this.searchParams.endFromTime = ''
        Object.assign(this.searchParams, this.initParams)
        this.search()
      },
      driverChange () {
        this.searchParams.driverId = this.executeUserStr ? this.executeUserStr.split('_')[2] : undefined
        this.search()
      },
      detailClose () {
        this.detailDrawer = false
      },
      handleIfUrgent (record) {
        this.formItem.id = record.id
        this.formItem.ifUrgent = !record.ifUrgent
        this.$http(this, {
          url: SERVICE_URLS.csgz.reportRecord.updateIfUrgent,
          data: this.formItem,
          loading: 'tableLoading',
          noTips: false,
          successTipsContent: record.ifUrgent ? '已更改为非紧急!' : '已加急',
          success: () => {
            this.search()
          }
        })
      },
      handleView (record) {
        return {
          on: {
            click: () => {
              this.detailDrawer = true
              this.$nextTick(() => {
                this.$refs.viewRef.loadData(record.id)
              })
            }
          }
        }
      },
      handleDamageReportAdd () {
        this.addModal = true
        if (this.$refs['addRef']) {
          this.$refs['addRef'].retSetForm()
        }
      },
      //登记集污点
      handleSpotAdd () {
        this.addSpotModal = true
      },
      handleSpotAddOkWithReport () {
        this.addSpotWithReport = true
        this.$refs['addSpotRef'].submitAdd()
      },
      handleSpotAddOk () {
        this.addSpotWithReport = false
        this.$refs['addSpotRef'].submitAdd()
      },
      closeSpotAddModal () {
        this.addSpotModal = false
      },
      addSpotSuccess (data) {
        this.addSpotModal = false
        this.$message.success('登记成功！')
        if (this.addSpotWithReport) {
          this.addModal = true
          this.$nextTick(() => {
            this.$refs.addRef.findGatherSpot(data)
          })
        }
      },
      addSpotError () {
        this.$message.warning('登记失败！')
        this.addSpotModal = false
      },
      //上报
      handleAddOk () {
        this.$refs['addRef'].submitAdd()
      },
      closeAddModal () {
        this.addModal = false
      },
      addSuccess () {
        this.addModal = false
        this.$message.success('登记成功！')
        this.search()
        this.getStatisticsData()
      },
      addError () {
        this.$message.warning('登记失败！')
        this.addModal = false
      },
      /*调度指派*/
      dispatch (record) {
        this.dispatchModal = true
        this.$nextTick(() => {
          this.$refs['dispatchRef'].loadData(record.id)
        })
      },
      dispatchOk () {
        this.$refs['dispatchRef'].submitEditDispatch()
      },
      closeDispatchModal () {
        this.dispatchModal = false
      },
      dispatchSuccess () {
        this.dispatchModal = false
        this.search()
        this.getStatisticsData()
      },
      dispatchError () {
        this.dispatchModal = false
      },
      toggle () {
        this.expand = !this.expand
      },
      exportWord () {
        const fileName = '登记上报-' + moment().format('YYYYMMDDHHmmss')
        this.handExportWord(fileName)
      },
      /*区域选择处理*/
      areaOnChange (options) {
        this.searchParams.areaNo = options[2]
        this.search()
      },
      onclickArea (areaCodes) {
        this.searchParams.codes = areaCodes.join(',')
      },
      selectSpotType () {
        this.search()
      }
    }
  }
</script>